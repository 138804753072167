import axios from "@/api/index";
/*企业登云新闻动态列表*/
export const serviceClimbingCloudList=()=>axios.get('/smart/serviceCapability/serviceClimbingCloudList')
/*工作动态列表*/
export const dynamicList=data=>axios.post('/smart/backgroundCompany/dynamicList',data)
/*所有省份地址*/
export const getProvinceList=()=>axios.get('/smart/regionAbbreviation/getProvinceList')
/*全国的荣誉*/
export const companyAllHonor=()=>axios.get('/smart/backgroundCompany/companyAllHonor')
/*工作动态详情*/
export const dynamicDetails=data=>axios.post('/smart/backgroundCompany/dynamicDetails',data)