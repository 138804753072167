<template>
    <div>
        <canvas id="canvas" height="686" width="840" @mousedown="down($event)">
            你的浏览器不支持canvas，快去升级浏览器吧！
        </canvas>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paint: false,
            ctx: null,
            startPoint: {},
            mapArray: [
                {
                    line1MoveTo: {x: 400, y: 450},
                    line1lineTo: {x: 800, y: 340},
                    line2moveTo: {x: 800, y: 340},
                    line2lineTo: {x: 840, y: 340},
                    text: '四川',
                    name:"sichuan",
                },
                {
                    line1MoveTo: {x: 455, y: 495},
                    line1lineTo: {x: 800, y: 380},
                    line2moveTo: {x: 800, y: 380},
                    line2lineTo: {x: 840, y: 380},
                    text: '重庆',
                    name:'chongqing'
                },
                {
                    line1MoveTo: {x: 590, y: 470},
                    line1lineTo: {x: 800, y: 420},
                    line2moveTo: {x: 800, y: 420},
                    line2lineTo: {x: 840, y: 420},
                    text: '安徽',
                    name:'anhui'
                },
                {
                    line1MoveTo: {x: 550, y: 480},
                    line1lineTo: {x: 800, y: 460},
                    line2moveTo: {x: 800, y: 460},
                    line2lineTo: {x: 840, y: 460},
                    text: '湖北',
                    name:'hubei'
                },
                {
                    line1MoveTo: {x: 520, y: 520},
                    line1lineTo: {x: 800, y: 500},
                    line2moveTo: {x: 800, y: 500},
                    line2lineTo: {x: 840, y: 500},
                    text: '湖南',
                    name:'hunan'
                },
                {
                    line1MoveTo: {x: 565, y: 530},
                    line1lineTo: {x: 800, y: 540},
                    line2moveTo: {x: 800, y: 540},
                    line2lineTo: {x: 840, y: 540},
                    text: '江西',
                    name:'jiangxi'
                },
                {
                    line1MoveTo: {x: 460, y: 540},
                    line1lineTo: {x: 800, y: 580},
                    line2moveTo: {x: 800, y: 580},
                    line2lineTo: {x: 840, y: 580},
                    text: '贵州',
                    name:'guizhou'
                },
                {
                    line1MoveTo: {x: 405, y: 560},
                    line1lineTo: {x: 800, y: 620},
                    line2moveTo: {x: 800, y: 620},
                    line2lineTo: {x: 840, y: 620},
                    text: '云南',
                    name:'yunnan'
                },
            ]
        }
    },
    created() {
        this.$nextTick(() => {
            this.setCanvas()
        })
    },
    methods: {
        down(e) {
            // 鼠标按下事件
            const x = e.offsetX;
            const y = e.offsetY;

            this.startPoint = {
                x: x,
                y: y,
            };

            if (x > 800) {
                for (let i = 0; i < this.mapArray.length; i++) {
                    const opt = this.mapArray[i];
                    const num = opt.line2lineTo.y
                    if (num - y < 20 && num - y >= 0) {
                        console.log(i)
                        this.goOtherURL(opt.name)
                        break;
                    }
                }
            }

            // if (this.eraser) { // 启用橡皮擦
            //   this.ctx.clearRect(x, y, 10, 10);
            // }
            this.paint = true;
        },
        //  跳转页面
        goOtherURL(name) {
            window.open('http://' + name+'.bangq1.com/#/')
        },
        setCanvas() {
            const canvas = document.getElementById('canvas');
            const ctx = canvas.getContext('2d')
            this.ctx = ctx;
            // 创建一个 image对象:
            var img = new Image();
            img.src = require('@/assets/img/newHoemImgs/ditu.png')
            // 当图片加载完之后运行：
            let _this = this;
            img.onload = function () {
                ctx.drawImage(img, 0, 0, 830, 686); // 在(0,0)处绘制原图
                const defaultColor = '#FE273B' //主题颜色
                _this.mapArray.forEach(row => {
                    ctx.beginPath()             //开启新路径
                    ctx.strokeStyle = defaultColor //颜色
                    ctx.moveTo(row.line1MoveTo.x, row.line1MoveTo.y) // 第一条线起点x,y
                    ctx.lineTo(row.line1lineTo.x, row.line1lineTo.y) //第一条线结束点 x,y
                    ctx.stroke()        //绘制
                    ctx.beginPath();
                    ctx.arc(row.line1MoveTo.x, row.line1MoveTo.y, 4, 0, [(Math.PI) / 180] * 360);//画圆形
                    ctx.fillStyle = defaultColor;
                    ctx.fill();
                    ctx.beginPath();
                    ctx.strokeStyle = defaultColor
                    ctx.moveTo(row.line2moveTo.x, row.line2moveTo.y) // 第二条线起点为(x,y)
                    ctx.lineTo(row.line2lineTo.x, row.line2lineTo.y)  //第二条线结束 x,y
                    ctx.stroke()
                    ctx.closePath();
                    ctx.font = '14px Verdana'
                    ctx.fillText(row.text, row.line2moveTo.x + 5, row.line2moveTo.y - 5, 200);
                    ctx.closePath();
                })
            }
        },
    }
}
</script>

<style>

</style>