<template>
  <div style="min-width: 1360px;">
    <new-cloude-header/>
    <newHomeHeaderBanner :content="bannerContent" title="帮企服务云" :showBtn="false"
                         :fbg-img="require('@/assets/img/newHoemImgs/bangqifuwuyun.png')"></newHomeHeaderBanner>
    <div class="container-box-one">
      <div class="container-title">
        <span class="title">领先的服务能力</span>
        <span class="tips">满足不同阶段业务需求，加速企业数字化转型</span>
      </div>
      <div class="el-tab-box">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.name" v-for="item in serverDatalist" :key="item.id">
            <item :show-btn="false" imgRight :imgStyle="{height:'370px'}" :imageSrc="item.image" :title="item.title" :content="item.content"/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="scheme-container">
      <div>
        <div class="scheme-container-title">提供更加垂直的行业解决方案</div>
        <div class="scheme-container-tips">电商零售、政务、园区、工厂，数智大脑都有完整解决方案</div>
      </div>
      <div class="swirch-cotnent">
        <div class="swirch-cotnent-left">
          <div @click="schemeCLick(i)" class="cell" v-for="(item,i) in schemeList" :key="i"
               :class="{isActive:schemeIndex == i}">{{ item.name }}
          </div>
        </div>
        <div class="swirch-cotnent-right">
          <div class="swirch-cotnent-right-title">{{ cuurRow.title }}</div>
          <div class="swirch-cotnent-right-content">{{ cuurRow.content }}</div>
          <!--<div class="btn info" @click="bannerClick(cuurRow.title)">立即咨询</div>-->
        </div>
      </div>
    </div>
    <div class="container-box-one">
      <div style="margin-top: 40px">
        <div class="all-title">全国业务范围</div>
      </div>
      <div style="margin:20px auto;display: flex;justify-content: center;">
        <allMap/>
      </div>
    </div>
    <div class="number-change-box-outer">
      <div class="number-change-box">
        <div class="change-left">
          <div class="change-left-title">
            <span>中国中小企业数字化转型的急先锋</span>
          </div>
          <div class="change-left-container">
            <span>重庆帮企科技集团有限公司是一家以互联网为基础的国家级高新技术企业、重庆市企业技术中心、重庆“专精特新”企业。同时拥有IDC\CDN\ISP\ICP\EDI《增值电信业务经营许可证》，坚持“一心为企业服务”的宗旨，致力于降低企业数字化门槛，为企业提供小型化、快速化、轻量化、精准化的数字化产品。</span>
          </div>
        </div>
        <div class="change-right">
          <div class="change-right-list">
            <div class="change-right-item">
              <div class="right-num">
                <span class="str-num">1.3</span>
                <span class="str-str">万</span>
              </div>
              <div class="right-title">
                <span class="str-str">企业用户</span>
              </div>
            </div>
            <div class="change-right-item">
              <div class="right-num">
                <span class="str-num">158</span>
                <span class="str-str">个</span>
              </div>
              <div class="right-title">
                <span class="str-str">覆盖城市</span>
              </div>
            </div>
            <div class="change-right-item">
              <div class="right-num">
                <span class="str-num">1000</span>
                <span class="str-str">+</span>
              </div>
              <div class="right-title">
                <span class="str-str">生态伙伴</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="certificate-box">
      <div class="certificate-box-title">获得多项资质证书，完善的专业服务能力</div>
      <div class="certificate-box-tips">为客户提供稳定、可靠、安全的产品服务</div>
      <div class="certificate-list">
        <div v-for="(item,i) in certificateList" :key="i">
          <div class="img-box">
            <el-image style="width: 100%; height: 100%" :src="item.image" fit="fill"></el-image>
          </div>
          <div class="certificate-list-title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <new-cloude-footer></new-cloude-footer>
    <applyTrial ref="dialog" :servicePlatform="servicePlatform" dialogTitle="立即咨询"/>
  </div>
</template>
<script>
import headerBanner from "@/components/assembly/headerBanner.vue";
import item from "@/components/assembly/item.vue";
import newHomeHeaderBanner from "@/views/home/newHomeHeaderBanner.vue";
import {serviceClimbingCloudList} from "@/api/newHomeApi";
import newCloudeHeader from "@/views/home/newCloudeHeader.vue";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
import allMap from '@/components/map/map'
import applyTrial from "@/components/assembly/applyTrial";
export default {
  name: "home1",
  components: {item, headerBanner, newHomeHeaderBanner, newCloudeHeader, newCloudeFooter, allMap,applyTrial},
  data() {
    return {
      content1: '基于组件化开发思想，平台打通应用软件从需求、涉及、开发、构建、测试、发布到部署的全流程，通过持续的调用与迭代，将丰富的项目积累和经验工具化、组件化、模板化，通过统一操作页面，真正做到让中小企业用户“开箱即用”。可以自由选用工具组件、业务应用、表单模板、智能化助手及解决方案；轻松实现流程、审批、报表、页面、数据处理等功能；按需生成Web应用、APP、大屏可视化应用、小程序等操作起来很方便，只需一键调用、简单“拖拽”和配置，就能在图形化的界面上，快速实现个性化业务功能定制，轻松完成应用搭建。',
      content2: '数字化工厂通过数字化技术的应用，实现生产过程的可控性、可视化、可量化和可优化，从而提高生产效率和质量，降低成本和风险，提升企业的竞争力和创新能力。MRO、ERP、MES、WMS、CRM、WMS、SRM等产品为基础，为客户提供数智化工厂整体解决方案。',
      content3: '从建立企业门户网站、数字办公、财务管理、数字店铺、协同办公等功能帮助企业从传统的工作方式进化到在线办公，建立企业数字化基座。降低数字化的门槛，为每个企业构建数字化统一入口，让数字化与产业发展深度融合。',
      content4: '打造面向中小企业上云的统一门户“企业登云”和覆盖云原生、云存储、云网络、云算力、云安全、云管理、云生态、云机房等领域的全栈云计算能力，为各行各业的中小企业数字化转型，提供与云计算相关的全链条、分布式的云计算服务及解决方案，形成“一云承载”共享共用共连的云服务体系。',
      content5: '中小企业大数据智能监测管理系统是通过大数据技术建立智能化监测系统，及时掌握辖区企业运行动态，全方位、立体化分析经济运行指标，清晰为企业完整画像，包括知识产权监测、运行企业监测、企业数据分析、企业风险监测、企业服务监测、银企服务监测、政策支持监测、能源监测、社保监测等20余个板块100多个子版块一体化全链企业数据。',
      content6: '为帮助中小企业高效智能匹配融资产品，也为金融机构精准获客提供引流服务，搭建“银企直贷”中小企业融资智能服务平台，邀请全市金融机构“开店摆摊”，打造“金融淘宝”，平台建设融资申请、信息查询、信用评价、贷后管理、风险监测等功能，通过接入水电、纳税、不动产交易、公积金、司法等20大类、100余项政府部门涉企数据及其他企业数据为基础，逐步完善涉及信用信息数据，推动企业和银行机构双向信息透明，促进银企融资对接高效开展。',
      content7: '深度融合政企交互场景，实现惠企政策主动推送、精准直达，打造服务企业的数字底座、监测管理底座。汇聚优质企服机构，实现统筹运营管理，满足不同发展阶段的中小企业对创新发展要素的相关需求，助力企业高质量发展。平台开设知识产权代理工商代办、会计服务、资质办理、法律服务等企业服务资源市场，实现服务资源“一网统管”，为中小企业提供全流程“一站式”服务。按照“平台+行业”、“平台+区域”、“平台+领域”等一系列创新模式，持续提升中小企业公共服务能力，为企业数字化转型、产业升级持续注入新动能。逐步形成生态化、数字化、智能化的中小企业综合服务网络体系。',
      bannerContent: '通过探索“数字化基座+云平台+应用”的智慧服务模式，建设“中小企业数字化智慧服务体系”，持续优化企业数字化转型服务，提升企业应用开发、企业数字化管理、企业登云、数字化能力诊断评估、政策宣传、服务资源对接、人才培训等数字化综合服务能力。构建以智慧服务为导向，“以数智企”贯穿到智慧服务全过程，结合中台化、低代码融合、智能化等新模式、新技术，形成“1+6+N”的企业数智化“新引擎”，即1个数字化基座、6个核心云平台（数智应用、数智管理、企业登云、智能服务、企业大数据、企业融资）、N个子应用系统协同场景于一体，能全面支撑各类企业持续推进数智化建设，让协同更智能，让组织更智慧。',
      activeName: '数智应用',
      certificateList: [
        {image: `${require('@/assets/img/home/certificate_icon1.png')}`, title: '高新技术企业'},
        {image: `${require('@/assets/img/home/certificate_icon2.png')}`, title: 'CDN许可证'},
        {image: `${require('@/assets/img/home/certificate_icon3.png')}`, title: '专精特新企业'},
        {image: `${require('@/assets/img/home/certificate_icon4.png')}`, title: '企业技术中心'}
      ],
      schemeList: [
        {
          name: '智慧政务',
          title: '智慧政务解决方案',
          content: '在建设智慧城市的大背景下，发展信息经济和智慧经济，实现经济和社会转型升级的必由之路。智慧政务是广泛运用物联网、云计算、移动互联网、人工智能、数据挖掘等现代信息技术，通过资源整合、流程优化、业务协同，提高政府办公、服务、监管、决策的智能化水平，从而形成高效、集约、便民的服务型政府运营模式。'
        },
        {
          name: '智慧园区',
          title: '智慧园区解决方案',
          content: '智慧园区是借助5G、物联网、大数据、BIM、GIS等新一代信息技术，对园区内人流、物流、信息流等数据进行实时收集、存储与分析，实现园区规划设计、实施建设、运营管理等，包括智慧交通系统、智慧能源系统、智慧园区安防系统、智慧环保系统、智慧楼宇系统等多个方面，构建“空间数字化”和“产业数字化”的数字孪生平台，打造兼容多场景多类型园区的统一平台；通过基座+集成+平台+运营的支撑服务体系，提供智慧园区行业级解决方案。',
        },
        {
          name: '智慧工厂',
          title: '智慧工厂解决方案',
          servicePlatform:'数智化工厂MES系统解决方案',
          content: '数字化工厂通过数字化技术的应用，实现生产过程的可控性、可视化、可量化和可优化，从而提高生产效率和质量，降低成本和风险，提升企业的竞争力和创新能力。MRO、ERP、MES、WMS、CRM、WMS、SRM等产品为基础，为客户提供数智化工厂整体解决方案。',
        },
        {
          name: '更多方案',
          title: '',
          content: '',
        }
      ],
      schemeIndex: 0,
      cuurRow: {},
      serverDatalist: [],
      servicePlatform:'',
    }
  },
  methods: {
    schemeCLick(index) {
      const row = this.schemeList[index];
      if (row.name != '更多方案') {
        this.schemeIndex = index;
        this.cuurRow = row
      } else {
        this.$router.push('/solution')
      }
    },
    // 获取服务能力
    getServiceClimbingCloudList() {
      serviceClimbingCloudList({request: 'form'}).then(res => {
        if (res.code == 200) {
          this.serverDatalist = res.content
        }
      })
    },
    // 服务能力切换
    handleClick(value) {},
    bannerClick(name) {
      this.servicePlatform=name;
      this.$refs.dialog.show = true;
    }
  },
  created() {
    this.cuurRow = this.schemeList[0];
    this.getServiceClimbingCloudList()
  }
}
</script>
<style lang="scss" scoped>
.number-change-box-outer {
  background-image: url("~@/assets/img/newHoemImgs/guanyuwomen.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 150px 0;

  .number-change-box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .change-left {
      width: 55%;

      .change-left-title {
        font-size: 30px;
        font-family: 'SourceHanSansCN';
        font-weight: bold;
        color: #FFFFFF;
      }

      .change-left-container {
        font-size: 18px;
        font-family: 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 50px;
      }
    }

    .change-right {
      width: 30%;
      padding: 80px 40px;
      background-color: rgba(255, 255, 255, 0.5);

      .change-right-list {
        display: flex;
        justify-content: space-between;
      }

      .str-num {
        font-size: 36px;
        color: rgba(241, 0, 22, 1);
        font-family: 'SourceHanSansCN';
      }

      .str-str {
        font-size: 18px;
        color: rgba(51, 51, 51, 1);
        font-family: 'SourceHanSansCN';
      }

    }
  }
}

.map-box {
  width: 830px;
  margin: 20px auto;

  .map-item {
    width: 100%;
  }
}

.all-title {
  font-size: 30px;
  font-family: 'SourceHanSansCN';
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.container-box-one {
  width: 1200px;
  margin: 0 auto;

  .container-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .title {
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      font-weight: bold;
      color: #333333;
      text-align: center;
      line-height: 70px;
    }

    .tips {
      font-size: 18px;
      font-family: 'SourceHanSansCN';
      font-weight: 500;
      color: #333333;
    }
  }
}

.btn {
  width: 200px;
  height: 50px;
  background-color: #fff;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
}

.info {
  color: #1935DE;
}

.scheme-container {
  width: 100%;
  box-sizing: border-box;
  height: 500px;
  background-image: url('~@/assets/img/home/f6b2f65407e5d1c9d436a1f005abdaf0_vzEgx5ZQAU.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-size: cover;

  &-title {
    font-size: 30px;
    color: #fff;
    text-align: center;
    padding-top: 60px;
    font-weight: bold;
    line-height: normal;
  }

  &-tips {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    line-height: normal;
  }

  .swirch-cotnent {
    height: 310px;
    width: 1200px;
    display: flex;

    &-left {
      width: 280px;
      background-color: rgba(16, 44, 90, 0.9);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .cell {
        width: 240px;
        height: 60px;
        color: #fff;
        font-size: 24px;
        padding-left: 30px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
      }

      .isActive {
        background-color: #1935DE;
      }
    }

    &-right {
      flex: 1;
      background-color: rgba(16, 44, 90, 0.7);
      box-sizing: border-box;
      padding: 30px 30px 0;
      color: #fff;

      &-title {
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 30px;
      }

      &-content {
        font-size: 16px;
        line-height: 1.8;
        text-align: justify;
        padding-bottom: 45px;
      }
    }
  }
}

.certificate-box {
  background: #F7F8FF;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 18px;
  }

  &-tips {
    font-size: 16px
  }

  .certificate-list {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;

    .img-box {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      overflow: hidden;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 16px;
    }
  }
}
</style>
<style scoped>
.el-tab-box >>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: none;
}

.el-tab-box >>> .el-tabs__item {
  background: linear-gradient(0deg, rgba(235, 236, 244, 0.99), rgba(234, 237, 254, 0.99), rgba(248, 249, 255, 0.99));
  border: 2px solid #FFFFFF;
  box-shadow: 0px 3px 6px 0px rgb(217 222 235);
  border-radius: 25px;
  width: 160px;
  font-size: 18px;
  font-family: 'SourceHanSansCN';
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: 50px;
  height: auto;
  margin: 10px 0;
}

.el-tab-box >>> .el-tabs--card > .el-tabs__header {
  border: none;
}

.el-tab-box >>> .el-tabs__header .el-tabs__item.is-active {
  background: #1935DE;
  color: #FFFFFF;
}
</style>
